import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Rifm } from 'rifm';
import moment from 'moment';


type Props = {
	type: any;
	defaultValue: any;
	value: any;
	style: any;
    onChange: any;
    placeholder: any;
    onBlur: any;
    disabled: any;

};

export const InputDate = ({ type, defaultValue, value, style, onChange, placeholder, onBlur, disabled }: Props) => {
	const [masked, setMasked] = useState(value);
	const [firstLoad, setFirstLoad] = useState(true);

	const parseDigits = (string: any) => (!string ? '' : (string.match(/\d+/g) || []).join(''));

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			return;
		}
		if (!masked) {
			return;
		}
	
		if ((moment(masked, 'DD-MM-YYYY').isValid()) && (moment(masked, 'DD-MM-YYYY').isAfter(moment('31-12-1900', 'DD-MM-YYYY'), 'year'))) {
			if (onChange) {
				onChange(masked);
			}
		} else {
			if (onChange) {
				onChange('');
			}
		}
	}, [masked]);

	const addMask = (string: any) => {
		const digits = parseDigits(string);
		const days = digits.slice(0, 2).padEnd(2, '_');
		const months = digits.slice(2, 4).padEnd(2, '_');
		const years = digits.slice(4, 8).padEnd(4, '_');
		return `${days}-${months}-${years}`;
	};

	const formatDate = (string: any) => {
		const digits = parseDigits(string);
		const chars = digits.split('');
		return chars
			.reduce((r: any, v: any, index: any) => (index === 2 || index === 4 ? `${r}-${v}` : `${r}${v}`), '')
			.substr(0, 10);
	};

	return (
		<Rifm
			value={masked}
			onChange={(value) => setMasked(value)}
			onBlur={(value: any) => setMasked(value)}
			format={formatDate}
            disabled={disabled}
			replace={addMask}>
			{({ value, onChange }) => (
				<Input
					value={value}
					style={style}
					defaultValue={defaultValue}
					placeholder={placeholder}
					onChange={(e) => {
						onChange(e);
					}}
					onBlur={onBlur}
				/>
			)}
		</Rifm>
	);
};
