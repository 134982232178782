import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Input, Modal, Form, Select, Spin } from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputNumber } from 'afrodita';
import TextArea from 'antd/lib/input/TextArea';

const { Text } = Typography;
const { Option, OptGroup } = Select;

const TableBankinter = ({ mortgageId, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [otherDatasAll, setOtherDatasAll] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const bankRequestId = location?.state !== undefined ? location.state.bankRequestId : undefined;
	const [emptyFields, setEmptyFields] = useState([]);
	const [selectValues, setSelectValues] = useState(undefined);

	const labelStyles = {
		fontSize: 10,
		color: '#2F4858',
		fontWeight: 400
	};

	useEffect(() => {
		getSelectValues();
		getOtherData();
	}, []);

	useEffect(() => {
		if (mortgage && otherDatasAll && selectValues) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(
					([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
				)
				.map(([fieldName]) => fieldName);

			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage, otherDatasAll, selectValues]);

	const getOtherData = () => {
		axios.get(`${env.api.url}/banks/evo/data/all`).then((response) => {
			setOtherDatasAll(response.data.data);
		});
	};

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const getSelectValues = () => {
		axios
			.get(`${env.api.url}/banks/bankinter/data/json`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setSelectValues(response.data.data);
			});
	};

	const renderOwnersForm = () => {
		const owners = mortgage.operation.owners;
		const arrayform = [];

		for (let i = 1; i <= owners; i++) {
			arrayform.push(
				<>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: i === 1 ? 32 : 24
							}}>
							{`Titular ${i}`}
						</Text>
					</Row>
					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`streetType${i}`}
								label={<Text style={labelStyles}>{'Tipo de calle'}</Text>}
								className={emptyFields.includes(`streetType${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.streetType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`streetName${i}`}
								label={<Text style={labelStyles}>{'Nombre de la calle'}</Text>}
								className={emptyFields.includes(`streetName${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`payRollDirectDepositBank${i}`}
								label={<Text style={labelStyles}>{'Nombre del banco'}</Text>}
								className={emptyFields.includes(`payRollDirectDepositBank${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`payRollAmountBeforeTax${i}`}
								label={<Text style={labelStyles}>{'Importe de la nómina antes de impuestos'}</Text>}
								className={emptyFields.includes(`payRollAmountBeforeTax${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`payRollAmountAfterTax${i}`}
								label={
									<Text style={labelStyles}>{'Importe de la nómina despues de impuestos'}</Text>
								}
								className={emptyFields.includes(`payRollAmountAfterTax${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ height: 40 }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`professionalCategory${i}`}
								label={<Text style={labelStyles}>{'Categoria profesional'}</Text>}
								className={emptyFields.includes(`professionalCategory${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{selectValues.professionalCategory.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`CNAECode${i}`}
								label={<Text style={labelStyles}>{'CNAE'}</Text>}
								className={emptyFields.includes(`CNAECode${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione" style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.cnaes.map((cnae) => {
											return (
												<OptGroup key={cnae.name} label={cnae.name}>
													{cnae.cnaes.map((item) => (
														<Option key={item.code} value={item.code}>
															{item.name}
														</Option>
													))}
												</OptGroup>
											);
										})}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`companyName${i}`}
								label={<Text style={labelStyles}>{'Nombre de la compañia'}</Text>}
								className={emptyFields.includes(`companyName${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={`companyPhone${i}`}
								label={<Text style={labelStyles}>{'Tlf de la compañia'}</Text>}
								className={emptyFields.includes(`companyPhone${i}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		setLoading(true);

		const obj = {
			bankRequestId: bankRequestId,
			operationId: mortgageId,
			earnestMoneySigned: values.earnestMoneySigned,
			fundsPurpose: values.fundsPurpose,
			fundsOrigin: values.fundsOrigin,
			nonFinancedFundsOrigin: values.nonFinancedFundsOrigin
		};

		const owners = mortgage.operation.owners;
		for (let i = 1; i <= owners; i++) {
			obj[`owner${i}Data`] = {
				addressDetails: {
					streetName: values[`streetName${i}`],
					streetType: values[`streetType${i}`]
				},
				economicDetails: {
					payRollDirectDepositBank: values[`payRollDirectDepositBank${i}`],
					payRollAmountBeforeTax: values[`payRollAmountBeforeTax${i}`],
					payRollAmountAfterTax: values[`payRollAmountAfterTax${i}`],
					professionalCategory: values[`professionalCategory${i}`],
					CNAECode: values[`CNAECode${i}`],
					companyName: values[`companyName${i}`],
					companyPhone: values[`companyPhone${i}`]
				}
			};
			setLoading(false);
		}
		axios
			.post(
				`${env.api.url}/banks/bankinter/create-request`,
				{
					...obj
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {});
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};

	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};

	if (otherDatasAll && loading === false && selectValues) {
		return (
			<>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
					Datos de la oferta solicitada
				</Text>
				<Form
					key={'bankinterForm'}
					form={form}
					layout="vertical"
					name="sendMessageForm"
					className="gb-form-offers-new"
					style={{ width: '100%' }}
					initialValues={{
						notes: mortgage.otherDataAdditional?.summary || undefined
					}}
					onChange={handleFormChange}
					onFinish={onFinish}>
					<Row gutter={[32, 4]} style={{ marginTop: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'earnestMoneySigned'}
								label={<Text style={labelStyles}>{'Dinero en arras firmado'}</Text>}
								className={emptyFields.includes('earnestMoneySigned') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									<Option value={true}>Sí</Option>
									<Option value={false}>No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'fundsPurpose'}
								label={<Text style={labelStyles}>{'Fondos Propósito'}</Text>}
								className={emptyFields.includes('fundsPurpose') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsPurpose.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'fundsOrigin'}
								label={<Text style={labelStyles}>{'Origen de los fondos'}</Text>}
								className={emptyFields.includes('fundsOrigin') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsOrigin.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								name={'nonFinancedFundsOrigin'}
								label={<Text style={labelStyles}>{'Origen de los fondos no financiados'}</Text>}
								className={emptyFields.includes('nonFinancedFundsOrigin') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione">
									{selectValues.fundsOrigin.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{renderOwnersForm()}
					<Row>
						<Col xs={24} md={24} sm={24} lg={24} xl={24} xxl={24}>
							<Form.Item name={'notes'} label={<Text style={labelStyles}>{`Notas`}</Text>}>
								<TextArea
									style={{
										borderColor: '#c0daee',
										borderRadius: '4px',
										width: '100%',
										height: '84px'
									}}
									placeholder={'Notas'}></TextArea>
							</Form.Item>
						</Col>
					</Row>

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => cancelSend()}
							type="dashed">
							Atras
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
					open={visibleCancel}
					title={
						<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
							{'Cancelar envío'}
						</Text>
					}
					okText={'Aceptar'}
					cancelText={'Cancelar'}
					width={404}
					onCancel={handleCancel}
					onOk={handleOk2}>
					<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
						{'¿Aceptas la cancelación del envio a Bankinter?'}
					</Text>
				</Modal>
			</>
		);
	} else {
		return (
			<>
				<div style={{ textAlign: 'center', height: '40vh' }}>
					<Spin style={{ position: 'absolute', top: '30%' }} size="large" />
				</div>
				<div style={{ textAlign: 'center', height: '80vh' }}>
					<Text style={{ marginTop: 34, fontSize: 14, fontWeight: 500 }}>
						Por favor, no cierre ni recargue la pagina, puede tardar hasta 3 minutos...
					</Text>
				</div>
			</>
		);
	}
};
export default TableBankinter;
