import React from 'react';
import { Select } from 'antd';

type Props = {
	id?: string;
	disabled?: boolean;
	defaultValue?: any;
	value?: any;
	placeholder?: string;
	style?: any;
	className?: string;
	onChange?: any;
	location?: boolean;
};

export const ProvinceSelector = ({
	id,
	disabled,
	defaultValue,
	value,
	placeholder,
	style,
	className,
	onChange,
	location = false
}: Props) => {
	let options = [];

	const customFilter = (input: string, option: any) => {
		const normalizedInput = input
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f\s]/g, '');
		const normalizedLabel = (option?.label ?? '')
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f\s]/g, '');

		if (normalizedLabel === normalizedInput) {
			return true;
		}

		const conditions = [
			{ provinces: ['menorca', 'mallorca', 'ibiza', 'formentera'], label: 'baleares' },
			{ provinces: ['elhierro', 'lagomera', 'lapalma', 'tenerife'], label: 'santacruzdetenerife' },
			{ provinces: ['fuerteventura', 'grancanaria', 'lanzarote'], label: 'laspalmas' }
		];

		for (const condition of conditions) {
			if (condition.provinces.some((province) => province.includes(normalizedInput))) {
				if (normalizedLabel === normalizedInput || normalizedLabel === condition.label) {
					return true;
				}
			}
		}

		return normalizedLabel.includes(normalizedInput);
	};

	if (location) {
		options = [
			{
				label: 'Madrid',
				value: 'MADRID',
				location: [40.4950635533, -3.71701564368]
			},
			{
				label: 'Barcelona',
				value: 'BARCELONA',
				location: [41.7309063623, 1.98377415091]
			},
			{
				label: 'Málaga',
				value: 'MALAGA',
				location: [36.8139413829, -4.72577351909]
			},
			{
				label: 'Valencia',
				value: 'VALENCIA',
				location: [39.3702810965, -0.800574529005]
			},
			{
				label: 'A Coruña',
				value: 'A_CORUÑA',
				location: [43.1260561888, -8.46413957018]
			},
			{
				label: 'Álava',
				value: 'ALAVA',
				location: [42.8350368004, -2.72045971703]
			},
			{
				label: 'Albacete',
				value: 'ALBACETE',
				location: [38.8254131169, -1.98030504638]
			},
			{
				label: 'Alicante',
				value: 'ALICANTE',
				location: [38.4785978267, -0.568607845131]
			},
			{
				label: 'Almería',
				value: 'ALMERIA',
				location: [37.1961344875, -2.34480243505]
			},
			{
				label: 'Asturias',
				value: 'ASTURIAS',
				location: [43.2923622489, -5.99341267596]
			},
			{
				label: 'Ávila',
				value: 'AVILA',
				location: [40.5710433661, -4.9455673741]
			},
			{
				label: 'Badajoz',
				value: 'BADAJOZ',
				location: [38.709799461, -6.14138741708]
			},
			{
				label: 'Baleares',
				value: 'ISLAS_BALEARES',
				location: [39.5740667938, 2.91246475611]
			},
			{
				label: 'Burgos',
				value: 'BURGOS',
				location: [42.3687161555, -3.58570638339]
			},
			{
				label: 'Cáceres',
				value: 'CACERES',
				location: [39.7119012737, -6.16077425542]
			},
			{
				label: 'Cádiz',
				value: 'CADIZ',
				location: [36.5538617868, -5.76031324938]
			},
			{
				label: 'Cantabria',
				value: 'CANTABRIA',
				location: [43.1977382267, -4.02930347409]
			},
			{
				label: 'Castellón',
				value: 'CASTELLON',
				location: [40.2412558074, -0.146776148614]
			},
			{
				label: 'Ceuta',
				value: 'CEUTA',
				location: [35.8936013921, -5.34290866234]
			},
			{
				label: 'Ciudad Real',
				value: 'CIUDAD_REAL',
				location: [38.925567409, -3.82803411061]
			},
			{
				label: 'Córdoba',
				value: 'CORDOBA',
				location: [37.9926909187, -4.80925951692]
			},
			{
				label: 'Cuenca',
				value: 'CUENCA',
				location: [39.8959907075, -2.19569389437]
			},
			{
				label: 'Girona',
				value: 'GIRONA',
				location: [42.1280015353, 2.67331871519]
			},
			{
				label: 'Granada',
				value: 'GRANADA',
				location: [37.3125255673, -3.26786786014]
			},
			{
				label: 'Guadalajara',
				value: 'GUADALAJARA',
				location: [40.8134207178, -2.62355108974]
			},
			{
				label: 'Guipúzcoa',
				value: 'GIPUZKOA',
				location: [43.1438118875, -2.19423188277]
			},
			{
				label: 'Huelva',
				value: 'HUELVA',
				location: [37.5772249559, -6.82936543182]
			},
			{
				label: 'Huesca',
				value: 'HUESCA',
				location: [42.2030837524, -0.0729676164737]
			},
			{
				label: 'Jaén',
				value: 'JAEN',
				location: [38.0164875742, -3.44166256414]
			},
			{
				label: 'La Rioja',
				value: 'LA_RIOJA',
				location: [42.2748915985, -2.51701416126]
			},
			{
				label: 'Las Palmas',
				value: 'LAS_PALMAS',
				location: [28.3654970886, -14.5477057996]
			},
			{
				label: 'León',
				value: 'LEON',
				location: [42.6198902098, -5.83988148418]
			},
			{
				label: 'Lleida',
				value: 'Lleida',
				location: [42.0438562414, 1.04765571774]
			},
			{
				label: 'Lugo',
				value: 'LUGO',
				location: [43.0117157152, -7.44602519023]
			},

			{
				label: 'Murcia',
				value: 'MURCIA',
				location: [38.0020693783, -1.48516166565]
			},
			{
				label: 'Melilla',
				value: 'MELILLA',
				location: [35.2910468792, -2.95046482652]
			},
			{
				label: 'Navarra',
				value: 'NAVARRA',
				location: [42.667173775, -1.64601593605]
			},
			{
				label: 'Ourense',
				value: 'OURENSE',
				location: [42.1963818787, -7.59234907833]
			},
			{
				label: 'Palencia',
				value: 'PALENCIA',
				location: [42.371758814, -4.53575570686]
			},
			{
				label: 'Pontevedra',
				value: 'PONTEVEDRA',
				location: [42.4353133968, -8.46105223453]
			},
			{
				label: 'Salamanca',
				value: 'SALAMANCA',
				location: [40.8049870683, -6.06543963906]
			},
			{
				label: 'Santa Cruz de Tenerife',
				value: 'SANTA_CRUZ_DE_TENERIFE',
				location: [28.3125972736, -17.0178020474]
			},
			{
				label: 'Segovia',
				value: 'SEGOVIA',
				location: [41.1709962782, -4.05414957688]
			},
			{
				label: 'Sevilla',
				value: 'SEVILLA',
				location: [37.4356869259, -5.68278978341]
			},
			{
				label: 'Soria',
				value: 'SORIA',
				location: [41.6207957877, -2.58881574575]
			},
			{
				label: 'Tarragona',
				value: 'TARRAGONA',
				location: [41.0877055863, 0.818032007117]
			},
			{
				label: 'Teruel',
				value: 'TERUEL',
				location: [40.6612507164, -0.815540233176]
			},
			{
				label: 'Toledo',
				value: 'TOLEDO',
				location: [39.7937167227, -4.14813389795]
			},
			{
				label: 'Valladolid',
				value: 'VALLADOLID',
				location: [41.6341199089, -4.84718613684]
			},
			{
				label: 'Vizcaya',
				value: 'BIZKAIA',
				location: [43.2380072718, -2.85183175498]
			},
			{
				label: 'Zamora',
				value: 'ZAMORA',
				location: [41.7271650242, -5.9805275019]
			},
			{
				label: 'Zaragoza',
				value: 'ZARAGOZA',
				location: [41.6204271624, -1.06454094506]
			}
		];
	} else {
		options = [
			{ label: 'A Coruña', value: 'A Coruña' },
			{ label: 'Álava', value: 'Álava' },
			{ label: 'Albacete', value: 'Albacete' },
			{ label: 'Alicante', value: 'Alicante' },
			{ label: 'Almería', value: 'Almería' },
			{ label: 'Asturias', value: 'Asturias' },
			{ label: 'Ávila', value: 'Ávila' },
			{ label: 'Badajoz', value: 'Badajoz' },
			{ label: 'Barcelona', value: 'Barcelona' },
			{ label: 'Baleares', value: 'Baleares' },
			{ label: 'Burgos', value: 'Burgos' },
			{ label: 'Cáceres', value: 'Cáceres' },
			{ label: 'Cádiz', value: 'Cádiz' },
			{ label: 'Cantabria', value: 'Cantabria' },
			{ label: 'Castellón', value: 'Castellón' },
			{ label: 'Ceuta', value: 'Ceuta' },
			{ label: 'Ciudad Real', value: 'Ciudad Real' },
			{ label: 'Córdoba', value: 'Córdoba' },
			{ label: 'Cuenca', value: 'Cuenca' },
			{ label: 'Girona', value: 'Girona' },
			{ label: 'Granada', value: 'Granada' },
			{ label: 'Guadalajara', value: 'Guadalajara' },
			{ label: 'Guipúzcoa', value: 'Guipúzcoa' },
			{ label: 'Huelva', value: 'Huelva' },
			{ label: 'Huesca', value: 'Huesca' },
			{ label: 'Jaén', value: 'Jaén' },
			{ label: 'La Rioja', value: 'La Rioja' },
			{ label: 'Las Palmas', value: 'Las Palmas' },
			{ label: 'León', value: 'León' },
			{ label: 'Lleida', value: 'Lleida' },
			{ label: 'Lugo', value: 'Lugo' },
			{ label: 'Madrid', value: 'Madrid' },
			{ label: 'Málaga', value: 'Málaga' },
			{ label: 'Murcia', value: 'Murcia' },
			{ label: 'Melilla', value: 'Melilla' },
			{ label: 'Navarra', value: 'Navarra' },
			{ label: 'Ourense', value: 'Ourense' },
			{ label: 'Palencia', value: 'Palencia' },
			{ label: 'Pontevedra', value: 'Pontevedra' },
			{ label: 'Salamanca', value: 'Salamanca' },
			{ label: 'Santa Cruz de Tenerife', value: 'Santa Cruz de Tenerife' },
			{ label: 'Segovia', value: 'Segovia' },
			{ label: 'Sevilla', value: 'Sevilla' },
			{ label: 'Soria', value: 'Soria' },
			{ label: 'Tarragona', value: 'Tarragona' },
			{ label: 'Teruel', value: 'Teruel' },
			{ label: 'Toledo', value: 'Toledo' },
			{ label: 'Valencia', value: 'Valencia' },
			{ label: 'Valladolid', value: 'Valladolid' },
			{ label: 'Vizcaya', value: 'Vizcaya' },
			{ label: 'Zamora', value: 'Zamora' },
			{ label: 'Zaragoza', value: 'Zaragoza' }
		];
	}

	return (
		<Select
			id={id}
			disabled={disabled}
			showSearch
			defaultValue={defaultValue}
			value={value}
			style={style}
			className={className}
			placeholder={placeholder}
			optionFilterProp="children"
			filterOption={customFilter}
			onChange={onChange}
			options={options}
		/>
	);
};
