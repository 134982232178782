import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Card, Input, Modal, Form, Select } from 'antd';
import axios from 'axios';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import moment from 'moment';
import ModalsIncludesComponent from '../../../../../../Components/ModalsAndAlerts/ModalsIncludesComponent';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const InfoBankOffersClaim = ({ request, dataBankRequest, mortgageId, loadData, mortgageName }) => {
	const [registerRequest, setRegisterRequest] = useState(false);
	const [form] = Form.useForm();
	const [resolveModal, setResolveModal] = useState(false);
	const [claimRequest, setClaimRequest] = useState(undefined);
	const [claimRequestCount, setClaimRequestCount] = useState(undefined);
	const [visible, setVisible] = useState(false);
	const [visibleConfirm, setVisibleConfirm] = useState(false);
	const [visibleConfirm2, setVisibleConfirm2] = useState(false);
	const [blockClaim, setBlockClaim] = useState(false);
	const [visibleConfirmComponent, setVisibleConfirmComponent] = useState(false);

	useEffect(() => {
		getBankRequestClaim();
	}, [dataBankRequest]);

	const getBankRequestClaim = () => {
		axios
			.get(
				`${env.api.url}/banks/bank-request-claim/by-bank-request/${request.id}`,

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setClaimRequestCount(response.data.data);
				if (response.data.data.bankRequestClaims.length > 2) {
					let newArray = {
						bankRequestClaims: []
					};
					newArray.bankRequestClaims = response.data.data.bankRequestClaims.slice(
						response.data.data.bankRequestClaims.length - 3
					);
					setClaimRequest(newArray);
				} else {
					setClaimRequest(response.data.data);
				}
			});
	};

	const handleRegisterRequest = () => {
		setRegisterRequest(!registerRequest);
	};
	const cancelRegisterRequest = () => {
		setRegisterRequest(!registerRequest);
	};

	const okRegisterRequest = () => {
		setRegisterRequest(!registerRequest);
	};

	const resolveOffer = () => {
		setResolveModal(!resolveModal);
	};

	const createClaim = (values) => {
		setBlockClaim(!blockClaim);

		axios
			.post(
				`${env.api.url}/banks/bank-request-claim/claim-offer`,
				{
					bankRequestId: values.bankRequestClaims[0].bankRequestId,
					operationId: values.bankRequestClaims[0].operationId
				},

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setBlockClaim(!blockClaim);

				setVisibleConfirm2(!visibleConfirm2);
				getBankRequestClaim();
			});
	};

	const createClaimEmpty = (values, id) => {
		setBlockClaim(!blockClaim);
		axios
			.post(
				`${env.api.url}/banks/bank-request-claim/claim-offer`,
				{
					bankRequestId: values.id,
					operationId: values.operationId
				},

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (id !== 'no') {
					setVisibleConfirm(!visibleConfirm);
				}
				setBlockClaim(!blockClaim);

				getBankRequestClaim();
			});
	};

	const hideAddTaskModal = () => {
		setVisible(false);
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				//createClaimEmpty(request, 'no');
                setVisible(!visible)
				setVisibleConfirmComponent(!visibleConfirmComponent)
				loadData();
			});
	};

	return (
		<>
			<Card
				style={{ border: '1px solid #C0DAEE', borderRadius: 4, minHeight: 175, height: '100%' }}>
				{claimRequest && claimRequest.bankRequestClaims.length === 0 && (
					<>
						<Row>
							<Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858' }}>
								La oferta se ha reclamado:
							</Text>
							&nbsp;
							<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>0 veces</Text>
						</Row>
						<Row style={{ marginTop: 8 }}>
							<Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
								Todavía no se ha reclamado la oferta
							</Text>
						</Row>
						<div style={{ width: '100', textAlign: 'center', marginTop: 16 }}>
							<Button
								disabled={
									dataBankRequest.status === 'error' || dataBankRequest.status === 'canceled'
										? true
										: false
								}
								className="button-secundary-gibobs"
								style={{ width: 150, marginTop: 16 }}
								onClick={() =>
									dataBankRequest.bankAccountData.email !== undefined
										? setVisibleConfirmComponent(!visibleConfirmComponent)
										: setVisible(!visible)
								}>
								Reclamar oferta
							</Button>
						</div>
					</>
				)}

				{claimRequest && claimRequest.bankRequestClaims.length > 0 && (
					<>
						<Row>
							<Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858' }}>
								La oferta se ha reclamado:
							</Text>
							&nbsp;
							<Text style={{ fontSize: 12, fontWeight: 600, color: '#02C3CD' }}>
								{claimRequestCount.bankRequestClaims.length} veces
							</Text>
						</Row>

						{claimRequest.bankRequestClaims.map((date) => {
							if (moment(date.claimDate).add(72, 'hours').diff(moment()) > 0) {
								return (
									<Row style={{ marginTop: 8 }}>
										<Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
											{moment(date.claimDate).format('DD/MM/YYYY - HH:mm')}{' '}
										</Text>
									</Row>
								);
							} else {
								return (
									<Row style={{ marginTop: 4 }}>
										<Text style={{ fontSize: 12, fontWeight: 500, color: '#748EA0' }}>
											{moment(date.claimDate).format('DD/MM/YYYY - HH:mm')}{' '}
										</Text>
										&nbsp;
										<Text style={{ fontSize: 12, fontWeight: 500, color: '#FF4D4F' }}>
											Mas de 72h{' '}
										</Text>
									</Row>
								);
							}
						})}
						<div style={{ width: '100', textAlign: 'center', marginTop: 16 }}>
							<Button
								className="button-secundary-gibobs"
								style={{ width: 150, marginTop: 'auto' }}
								onClick={() => setVisibleConfirmComponent(!visibleConfirmComponent)}>
								Reclamar de nuevo
							</Button>
						</div>
					</>
				)}
			</Card>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Solicitudes extras'}
					</Text>
				}
				closable={true}
				visible={registerRequest}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							okRegisterRequest();
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => cancelRegisterRequest()}>
				<Form
					form={form}
					layout="horizontal"
					name="basic"
					//onFinish={onFinish}
				>
					<Row style={{ marginTop: -35, marginBottom: -30 }}>
						<Col lg={24} style={{ textAlignLast: 'left' }}>
							<Form.Item
								style={{ display: 'table-caption', flexDirection: 'column' }}
								label={''}
								name="request">
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>
									{'Tipo de solicitud extra'}
								</Text>
								<Select placeholder="Seleccione" style={{ width: '240px' }}>
									<Option value="documentation">Documentación</Option>
									<Option value="operation">Replanteamiento de la operación</Option>
									<Option value="others">Otros</Option>
								</Select>
							</Form.Item>

							<Form.Item
								style={{ display: 'flex', flexDirection: 'column', marginTop: -15 }}
								label={''}
								name="description">
								<Text style={{ fontSize: 10, fontWeight: 400, color: '#2F4858' }}>Explicación</Text>
								<TextArea
									style={{ height: 72 }}
									placeholder={
										'Incluye las nuevas solicitudes que te haya trasladado el banco'
									}></TextArea>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Solicitudes extras'}
					</Text>
				}
				closable={true}
				visible={resolveModal}
				onOk={() => resolveOffer()}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => resolveOffer()}
				width={400}>
				<div style={{ marginTop: -30, marginBottom: 10 }}>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						¿Quieres resolver esta solicitud extra?
					</Text>
				</div>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Reclamación de oferta'}
					</Text>
				}
				closable={true}
				confirmLoading={blockClaim}
				open={visibleConfirm}
				onOk={() => createClaimEmpty(request)}
				okText={'Si'}
				cancelText={'No'}
				onCancel={() => setVisibleConfirm(!visibleConfirm)}
				width={400}>
				<div style={{ marginTop: 0, marginBottom: 10 }}>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						¿Estás seguro que quieres enviar una reclamacion al banco?
					</Text>
				</div>
			</Modal>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title={
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						{'Reclamación de oferta'}
					</Text>
				}
				closable={true}
				confirmLoading={blockClaim}
				visible={visibleConfirm2}
				onOk={() => createClaim(claimRequest)}
				okText={'Si'}
				cancelText={'No'}
				onCancel={() => setVisibleConfirm2(!visibleConfirm2)}
				width={400}>
				<div style={{ marginTop: 0, marginBottom: 10 }}>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						¿Estás seguro que quieres volver a enviar una reclamacion al banco?
					</Text>
				</div>
			</Modal>

			<Modal
				open={visible}
				className="gb-modal-style-common ModalCommonSendMessage"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				title="Registrar asignación"
				okText="Aceptar"
				cancelText="Cancelar"
				width={496}
				onCancel={() => hideAddTaskModal()}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							handleSubmitForm(values, request.id);
							form.resetFields();
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<div>
					<Text style={{ fontSize: 12, color: '#2F4858', fontWeight: 400 }}>
						El ejecutivo de cuentas no está registrado todavía. Por favor, introduce sus datos para
						poder asignarle esta operación.
					</Text>
				</div>

				<Form
					form={form}
					layout="vertical"
					name="normal_login"
					className="login-form"
					initialValues={request !== undefined ? request : {}}
					style={{ marginTop: 16 }}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="name"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nombre y Apellidos'}
									</Text>
								}>
								<Input placeholder="Nombre y Apellidos" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="email"
								rules={[
									{
										required: true
									}
								]}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Email'}</Text>
								}>
								<Input type="email" placeholder="Email" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="phone"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Telefono'}
									</Text>
								}>
								<Input type="number" placeholder="Telefono de contacto" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								className="inputFormAnswers"
								name="office"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Oficina/Sucursal'}
									</Text>
								}>
								<Input placeholder="Información de oficina/sucursal" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
						<Col span={24}>
							<Form.Item
								className="inputFormAnswers"
								name="reference"
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Referencia'}
									</Text>
								}>
								<Input placeholder="Referencia del banco" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{visibleConfirmComponent && dataBankRequest && (
				<ModalsIncludesComponent
					bankRequestId={dataBankRequest?.id}
					idBank={dataBankRequest?.bankId}
					mortgageId={dataBankRequest?.operationId}
					visibleConfirm={visibleConfirmComponent}
					bankRequest={dataBankRequest}
					mortgageName={mortgageName}
					getBankRequestClaim={getBankRequestClaim}
					setVisibleConfirm={setVisibleConfirmComponent}
					notClaim={false}
					></ModalsIncludesComponent>
			)}
		</>
	);
};
export default InfoBankOffersClaim;
