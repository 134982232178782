import React, { useEffect, useState } from 'react';
import {
	Col,
	Layout,
	Row,
	Button,
	Input,
	Typography,
	Spin,
	Switch,
	Card,
	message,
	Modal
} from 'antd';
import { DoubleRightOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { updateStateMortgages } from '../../../../../store/mortgages/actions';
import DataNewFormat from './DataNewFormat';
import ProfileOfTheHolders from './ProfileOfTheHolders';
import { dataClient } from '../../../../../store/auth/actions';
import EditorMonaco from '@monaco-editor/react';

const { Content } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

export default (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const { mortgageId, otherDataAdditional } = props;
	const mortgage = props.mortgage || {};
	const [mortgageData, setMortgageData] = useState(undefined);
	const [mortgageVerifiedData, setMortgageVerifiedData] = useState(undefined);
	const [mortgageDataAll, setMortgageDataAll] = useState({});
	const [mortgageVerifiedDataAll, setMortgageVerifiedDataAll] = useState(undefined);
	const showModal = useSelector((state) => state.mortgages.showModal);
	const [isSabadell, setIsSabadell] = useState(false);
	const [dataClientVar, setDataClientVar] = useState(undefined);
	const [banks, setBanks] = useState(undefined);
	const dataClientRedux = useSelector((state) => state.auth.dataClient);
	const [isEditable, setIsEditable] = useState(false);
	const [inputOtherDataAdditional, setInputOtherDataAdditional] = useState('');
	const [showExtraData, setShowExtraData] = useState(false);

	useEffect(() => {
		setDataClientVar(dataClientRedux);
		getBanks();
		getManagementId();
	}, []);

	useEffect(() => {
		loadData();
		setDataClientVar(dataClientRedux);
	}, [mortgageUpda, showModal]);

	useEffect(() => {
		loadData();
		setDataClientVar(dataClientRedux);
	}, [mortgageUpda]);

	useEffect(() => {
		if (Object.keys(mortgage).length) {
			loadVerifiedData();
		}
	}, [mortgage]);

	const getBanks = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setBanks(response.data.data);
			});
	};

	const loadData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data-client/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageDataAll(response.data.data);
				setMortgageData(response.data.data.mortgage);
			});
	};

	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.data.code === 'sabadell-es') {
					setIsSabadell(true);
				}
			});
	};

	const loadVerifiedData = () => {
		axios
			.get(
				mortgage?.subtype === 'subrogation'
					? `${env.api.url}/mortgages/subrogation/get-data-hadmin/${mortgageId}`
					: `${env.api.url}/mortgages/mortgage/get-data-hadmin/${mortgageId}`,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (mortgage?.subtype === 'subrogation') {
					setMortgageVerifiedData(response.data.data.currentPropertyMortgage);
				} else {
					setMortgageVerifiedData(response.data.data.propertyWishPurchase);
				}
				const owner = response?.data?.data?.owners;
				const guarantor = response?.data?.data?.guarantors;
				const ownerData = [];

				if (owner) {
					for (let i = 0; i <= Number(owner.length) - 1; i++) {
						ownerData[`owner${i + 1}`] = { ...owner[i] };
					}
					if (guarantor) {
						for (let i = 0; i <= Number(guarantor.length) - 1; i++) {
							ownerData[`guarantor${i + 1}`] = { ...guarantor[i] };
						}
					}

					setMortgageVerifiedDataAll({ ...ownerData, ...response?.data?.data });
				}
			});
	};

	const updateOtherDataAdditional = () => {
		const obj = {
			otherDataAdditional: { summary: inputOtherDataAdditional }
		};
		axios
			.post(
				mortgage?.subtype === 'subrogation'
					? `${env.api.url}/mortgages/subrogation/update-data-hadmin/${mortgageId}`
					: `${env.api.url}/mortgages/mortgage/update-data-hadmin/${mortgageId}`,
				obj,
				{
					headers: {
						Authorization: `Bearer ${authClient.getToken()}`
					}
				}
			)
			.then((response) => {
				if (response.data.success) {
					message.success('La información se ha guardado correctamente');
					dispatch(updateStateMortgages(!mortgageUpda));
				} else {
					message.error('Ha ocurrido un error');
				}
				setIsEditable(false);
			})
			.catch(() => message.error('Ha ocurrido un error'));
	};

	const sendDeclaratives = async () => {
		await dataMultiple('process', 'basicCompleted', true, 'process', 'completedHadmin', true);
		await dataMultiple('process', 'pendingPay', true, 'payments', 'sawServiceType', 'Si');
		await sendDeclarative();
	};

	const sendDeclarative = () => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/check-full-declaratives`,
				{ operationId: mortgageId },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					setTimeout(() => {
						dispatch(updateStateMortgages(!mortgageUpda));
					}, 2000);
				}
			});
	};

	const dataMultiple = (key, owner, value, key1, owner1, value1) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: key,
							subkey: owner,
							value: value
						},
						{
							key: key1,
							subkey: owner1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					setTimeout(() => {
						dispatch(updateStateMortgages(!mortgageUpda));
					}, 2000);
				}
			});
	};

	const getFormatData = (title, data) => (
		<div>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: '#2F4858',
					marginRight: '4px'
				}}>
				{title}
			</Text>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: '#02C3CD'
				}}>
				{data}
			</Text>
		</div>
	);

	const ownerList = [];

	for (
		let i = 1;
		i <=
		Number(dataClientVar ? mortgageData.participants : mortgageVerifiedDataAll?.owners?.length);
		i++
	) {
		ownerList.push(`owner${i}`);
	}

	for (
		let i = 1;
		i <=
		Number(dataClientVar ? mortgageData.guarantors : mortgageVerifiedDataAll?.guarantors?.length);
		i++
	) {
		ownerList.push(`guarantor${i}`);
	}

	const newContentEditable = (
		<Content style={{ paddingLeft: 0 }} className="operations-data-page">
			<Row gutter={24} style={{ marginBottom: '16px' }}>
				<Col lg={18}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Switch
							defaultChecked={dataClientVar}
							style={{ background: !dataClientVar ? '#C0DAEE' : '#02C3CD' }}
							size="small"
							onChange={(e) => {
								setDataClientVar(e);
								dispatch(dataClient(e));
							}}
						/>
						<div
							style={{
								marginLeft: 12,
								color: dataClientVar ? '#2F4858' : '#C0DAEE'
							}}>
							{dataClientVar ? 'Datos del cliente' : 'Datos verificados'}
						</div>
					</div>
				</Col>
				<Col lg={6} style={{ textAlign: 'right' }}>
					{(mortgage.stage === 'basic' || mortgage.stage === 'full') && (
						<Button
							onClick={async () => sendDeclaratives()}
							style={{ borderColor: '#B37FEB', borderRadius: 4 }}>
							<Text style={{ color: '#B37FEB', fontSize: 12, fontWeight: 500 }}>
								Marcar BÁSICOS completado
							</Text>
						</Button>
					)}
					<Button
						type="primary"
						style={{ marginLeft: 24 }}
						className="button-primari-gibobs"
						disabled={dataClientVar ? true : false}
						onClick={() => navigate(`/operations/mortgages/${mortgageId}/editData`)}>
						Editar datos
					</Button>
				</Col>
			</Row>
			{mortgageVerifiedData && mortgageData && mortgage.subtype !== undefined && (
				<DataNewFormat
					mortgage={dataClientVar ? mortgageData : mortgageVerifiedData}
					subrogation={
						dataClientVar && mortgageDataAll.subrogation
							? mortgageDataAll.subrogation
							: mortgageVerifiedData ?? null
					}
					operationType={mortgage?.subtype}
					dataClient={dataClientVar}
					banks={banks}
					isSabadell={isSabadell}
					mortgageVerifiedDataAll={mortgageVerifiedDataAll}
				/>
			)}
			<Row style={{ marginTop: 24 }}>
				<Col style={{ display: 'flex', alignItems: 'center', gap: 8 }} span={24}>
					<DoubleRightOutlined style={{ color: '#02C3CD', fontSize: 18 }} />
					<Text style={{ fontSize: '14px', fontWeight: '600', color: '#2F4858' }}>
						Presentación de la operación
					</Text>
				</Col>
				<Col span={24}>
					{/* {isEditable ? (
						<div>
							<TextArea
								defaultValue={otherDataAdditional && otherDataAdditional?.summary}
								onChange={(e) => setInputOtherDataAdditional(e.target.value)}
								style={{ width: '100%', height: 64, marginTop: 12 }}
								placeholder="Incluir una presentacion tal y como el se presentaria a un banco en el email" />
							<Button
								type='primary'
								style={{ float: 'right', marginTop: 8, width: 120, height: 32 }}
								onClick={updateOtherDataAdditional}>
								Guardar
							</Button>
						</div>
					) : ( */}
					<div
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							marginTop: 16,
							background: '#F1F7F8',
							height: 'auto',
							padding: '8px 12px',
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#748EA0' }}>
							{otherDataAdditional && otherDataAdditional?.summary !== undefined
								? otherDataAdditional?.summary
								: ''}
						</Text>
						{/* <Button type="primary"
								icon={<EditOutlined />}
								style={{ width: 40, height: 40, marginTop: 4 }}
								onClick={() => setIsEditable(true)} /> */}
					</div>
					{/* )} */}
				</Col>
			</Row>
			<Row gutter={24}>
				{ownerList &&
					ownerList.map((owner, index) => (
						<ProfileOfTheHolders
							key={index}
							loadData={loadData}
							dataOwners={`${owner}`}
							owners={dataClientVar ? mortgageDataAll : mortgageVerifiedDataAll}
							userMortageId={mortgageId}
							dataClient={dataClientVar}
							nameBanks={banks}
						/>
					))}
			</Row>
			<Row gutter={24} style={{ marginTop: '32px' }}>
				<Col span={24}>
					<Text
						style={{
							fontSize: '14px',
							fontWeight: '600',
							color: '#2F4858',
							marginRight: '4px'
						}}>
						Otros datos
					</Text>
				</Col>

				<Col span={24}>
					<TextArea
						rows={4}
						placeholder="Comentarios"
						value={
							dataClientVar
								? mortgageData.otherdata
								: mortgageVerifiedDataAll?.otherDataAdditional?.otherData
						}
						defaultValue={
							dataClientVar
								? mortgageData.otherdata
								: mortgageVerifiedDataAll?.otherDataAdditional?.otherData
						}
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							marginTop: '16px',
							width: '100%',
							height: 160
						}}
					/>
				</Col>
			</Row>
			{isSabadell && (
				<Row gutter={24} style={{ marginTop: '32px' }}>
					<Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
						<Text
							style={{
								fontSize: '14px',
								fontWeight: '600',
								color: '#2F4858',
								marginRight: '4px'
							}}>
							Datos extra Sabadell
						</Text>
						<EyeOutlined
							onClick={() => setShowExtraData(!showExtraData)}
							style={{ color: '#02C3CD', fontSize: 18, cursor: 'pointer' }}
						/>
					</Col>
					<Col span={24} style={{ marginTop: 16, marginLeft: 16 }}>
						{getFormatData('Edad:', mortgageDataAll?.extraData?.age || '-')}
						{getFormatData('Origen:', mortgageDataAll?.extraData?.source || '-')}
						{getFormatData('Código Postal:', mortgageDataAll?.extraData?.zipCode || '-')}
						{getFormatData('Momentum:', mortgageDataAll?.extraData?.momentum || '-')}
						{getFormatData('Importe de compra:', mortgageDataAll?.extraData?.maxBudget || '-')}
						{getFormatData(
							'Es cliente del banco:',
							mortgageDataAll?.extraData?.isBankClient !== undefined
								? mortgageDataAll?.extraData?.isBankClient === 'no'
									? 'No'
									: mortgageDataAll?.extraData?.isBankClient === 'yes'
									? 'Sí'
									: '-'
								: '-'
						)}
						{getFormatData('Gastos mensuales:', mortgageDataAll?.extraData?.mensualCosts || '-')}
						{getFormatData(
							'Compra solo o acompañado:',
							mortgageDataAll?.extraData?.participants !== undefined
								? mortgageDataAll?.extraData?.participants === 'yes'
									? 'Si'
									: 'No'
								: 'No se sabe'
						)}
						{getFormatData('Ingresos:', mortgageDataAll?.extraData?.mensualIncomes || '-')}
						{getFormatData('Importe hipoteca:', mortgageDataAll?.extraData?.mortgageAmount || '-')}
						{getFormatData(
							'Ahorros estimados:',
							mortgageDataAll?.extraData?.estimatedNecessarySavings || '-'
						)}
						{getFormatData('Tin inicial:', mortgageDataAll?.extraData?.tinInitial || '-')}
						{getFormatData('Tin final:', mortgageDataAll?.extraData?.tinFinal || '-')}
						{getFormatData('TAE:', mortgageDataAll?.extraData?.tae || '-')}
						{getFormatData(
							'Cuota preconcedida:',
							mortgageDataAll?.extraData?.quotePreconceded || '-'
						)}
						{getFormatData('IND Cliente:', mortgageDataAll?.extraData?.indClient || '-')}
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: 24 }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Text style={{ fontSize: '14px', fontWeight: '600', color: '#2F4858' }}>
						Descripción de la operación B2B
					</Text>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							marginTop: 16
						}}>
						<Text
							style={{
								fontSize: '12px',
								fontWeight: '400px',
								color: '#748EA0'
							}}>
							{mortgage && mortgage.operationDescription !== undefined
								? mortgage.operationDescription
								: ''}
						</Text>
					</Card>
				</div>
			</Row>
			<Modal
				className="ModalCommon"
				closable
				centered
				width={'50vw'}
				onCancel={() => {
					setShowExtraData(false);
				}}
				footer={null}
				open={showExtraData}>
				<div style={{ padding: '32px 16px' }}>
					<EditorMonaco
						height="70vh"
						defaultLanguage={'javascript'}
						theme="vs-dark"
						defaultValue={JSON.stringify(mortgageDataAll?.extraData, null, '\t')}
					/>
				</div>
			</Modal>
		</Content>
	);

	return (
		<>
			{mortgageData ? (
				newContentEditable
			) : (
				<div style={{ textAlign: 'center', height: '100vh' }}>
					<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
				</div>
			)}
		</>
	);
};
